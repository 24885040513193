const TrashIcon = () => {
    return (
      <div>
        <i class="fa-solid fa-trash-can"></i>
      </div>
    );
  };


    export default TrashIcon;
