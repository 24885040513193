const CartIcon = () => {
    return (
      <div>
        <i className="fa-solid fa-cart-shopping"></i>
      </div>
    );
  };


    export default CartIcon;


